import locationService from '../../../../services/provider/location'

export default {
    data() {
        return {
            item: {
                supplier_org_id: null,
                location_id: null,
                idn: null,
                location_name: null,
                address1: null,
                address2: null,
                city: null,
                state: null,
                zip_code: null
            },
            formType: 'INSERT',
            labelName: {
                location_id: 'Location Id',
                idn: 'IDN/Hospital Name',
                location_name: 'Location Name',
                address1: 'Address 1',
                address2: 'Address 2',
                city: 'City',
                state: 'State',
                zip_code: 'Zip'
            },

            listSupplier: []
        }
    },
    computed: {
        form(){
            if(this.formType == 'INSERT'){
                return {
                    title: 'New Location',
                    disabledLocationId: false,
                    showIDN: false,
                    showSupplierName: false,
                    showSupplierSelection: true
                }
            }
            if(this.formType == 'UPDATE'){
                return {
                    title: 'Update Location',
                    disabledLocationId: true,
                    showIDN: true,
                    showSupplierName: true,
                    showSupplierSelection: false
                }
            }
        }
    },
    methods: {
        forceRerenderInsert() {
            this.formType = 'INSERT'
            this.getSupplierMeta()
        },
        forceRerenderUpdate(location_id) {
            this.formType = 'UPDATE'
            this.getLocation(location_id)
        },
        async save() {
            var isValid = await this.$refs['observer'].validate()
            if(isValid && this.formType == 'INSERT'){
                this.createLocation()
            }
            if(isValid && this.formType == 'UPDATE'){
                this.updateLocation()
            }
        },
        createLocation() {
            locationService.createLocation(this.item).then(resp =>{
                if (!resp.error){
                    this.$emit('onCompleted', 'INSERT')
                    this.$refs.modal.hide()
                }
            })
        },
        updateLocation() {
            locationService.updateLocation(this.item).then(resp =>{
                if (!resp.error){
                    this.$emit('onCompleted', 'UPDATE')
                    this.$refs.modal.hide()
                }
            })
        },
        getLocation(location_id) {
            locationService.getLocation(location_id).then(resp =>{
                if (!resp.error){
                    this.item = resp.data.d
                    this.$nextTick(()=>{
                        this.$refs.modal.show()
                    })
                }
            })
        },
        getSupplierMeta() {
            locationService.getSupplierMeta().then(resp =>{
                if (!resp.error){
                    this.listSupplier = resp.data.d
                    this.$nextTick(()=>{
                        this.$refs.modal.show()
                    })
                }
            })
        },
    }
}