import locationService from '../../../services/provider/location'
import userServices from '../../../services/user'
import LocationModal from './LocationModal'
import { AG_GridCustomizedTableHeaders_Key, txtFormatter, trimWhiteSpaces } from '../../../misc/tableHeaders'
import { OUTPUT_SHORTDATE_FORMAT } from '../../../misc/commons'
import { _findObj, toLocal, } from '../../../misc/genericFunctions'
import { getOrgId } from '../../../misc/auth'
import HeaderCheckBox from '../../../components/noti/TableGrid/CustomComponent/headerCheckBox.vue'
import CustomDropDown from '../../../components/noti/TableGrid/CustomComponent/customDropDown.vue'

export default {
    components: {
        LocationModal,
        HeaderCheckBox,
        CustomDropDown
    },
    data() {
        return {
            total: 0,
            items: [],
            Listitems: [],
            userIds: [],
            search: {
                supplier_id: null,
                state: null,
                city: null,
                verified: null,
                status: null,
                invalid: null,
                notcovered: null,
                supplieraddress1: null,
                supplieraddress2: null,
                suppliercity: null,
                supplierstate: null,
                supplierzipcode: null,
                provideraddress1: null,
                provideraddress2: null,
                providercity: null,
                providerstate: null,
                providerzipcode: null,
                suporgname: null,
                locationname: null,
            },
            searchQuery: { pageNumber: 1, pageSize: 50, sort: null, colId: null },
            isCheckAll: false,
            isEnable: false,
            isCheckboxEnable: false,
            verifyLocation: [],
            terms: false,
            sitesMetas: [],
            locationModalKey: 0,
            showE: false,
            showV: true,
            listSupplier: [],
            listChecked: [],
            centralizedData: false,
            columnDefs: [],
            gridOptions: {
                rowSelection: 'multiple',
                rowModelType: 'serverSide',
                suppressRowClickSelection: true,
                enableRangeSelection: true,
                onFilterChanged: this.onFilterChanged,
                onSortChanged: this.onSortChanged,
                onSelectionChanged: this.onSelectionChanged
            },
            rowModelType: 'serverSide',
            defaultColDef: {
                editable: true,
                enableValue: true,
                sortable: true,
                resizable: true,
                filter: true,
                flex: 1,
            },

            rowData: [],
            gridApi: null,
            columnApi: null,
            table_Connection_Data: this.$store.state.CustomizedHeaders.filter(s => s.userId == this.$store.state.user.id)[0],

        }
    },
    beforeMount() {

        this.columnDefs = [
            {  minWidth: 60, field: 'multichoice', type: 'rightColumn', hide: false, headerName: '', suppressMenu: true, suppressSorting: true, editable: true,suppressColumnsToolPanel: true,
                headerComponent: 'HeaderCheckBox', HeaderChangeCallback: this.headerChangeCallback, suppressMovable: true,
                checkboxSelection: function (params) {  return params.columnApi.getRowGroupColumns().length === 0},
                headerCheckboxSelection: function (params) { return params.columnApi.getRowGroupColumns().length === 0},
            },
            { minWidth: 150, field: 'supplier_org_name', filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: false, headerName: 'Supplier', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
            { minWidth: 150, field: 'location_name', filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: false, headerName: 'Supplier Location', filter: 'agTextColumnFilter', sortable: true, suppressColumnsToolPanel: true, menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
            { minWidth: 120, field: 'address1', filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: false, headerName: 'Supplier Address 1', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
            { minWidth: 120, field: 'address2', filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: false, headerName: 'Supplier Address 2', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
            { minWidth: 90, field: 'city', filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: false, headerName: 'Supplier City', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
            { minWidth: 100, field: 'state', filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: false, headerName: 'Supplier State', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
            { minWidth: 80, field: 'zip_code', filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: false, headerName: 'Supplier Zip', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
            { minWidth: 250, field: 'site_id', filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, headerName: 'Provider Site',  hide: false, sortable: true,
                cellRenderer: 'CustomDropDown',
                HeaderChangeCallback: this.ChangeSiteData,
                cellRendererParams: (params) => {
                    return {
                        listItems: this.sitesMetas,
                        trackBy: 'value',
                        labelBy: 'text',
                        value: params.value,

                    }
                }
            },
            { minWidth: 120, field: 'site_address1', filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: false, headerName: 'Provider Address 1', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
            { minWidth: 120, field: 'site_address2', filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: false, headerName: 'Provider Address 2', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
            { minWidth: 90, field: 'site_city', filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: false, headerName: 'Provider City', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
            { minWidth: 100, field: 'site_state', filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: false, headerName: 'Provider State', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
            { minWidth: 80, field: 'site_zip', filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces}, hide: false, headerName: 'Provider Zip', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'] },
            {
                minWidth: 80, field: 'status', type: 'centerColumn',filterParams: {textFormatter: txtFormatter, textCustomComparator: trimWhiteSpaces , filterOptions: ['equals', 'blank', 'notBlank'] }, hide: false, headerName: 'Status', sortable: true, filter: 'agTextColumnFilter', menuTabs: ['generalMenuTab', 'filterMenuTab', 'columnsMenuTab'], cellRenderer: (params) => {
                    let img = null
                    if (params.value === 'Covered') {
                        img = require('@/assets/img/Notisphere_Icon_Crescent_Mono_Dark.svg')
                    }
                    else if (params.value === 'Emailed') {
                        img = require('@/assets/img/at-svg.svg')
                    }
                    else if (params.value === 'NotCovered') {
                        img = require('@/assets/img/icon-mail.svg')
                    }
                    else if (params.value === 'Invalid') {
                        img = require('@/assets/img/close-icon.svg')
                    }
                    return `<img src=${img} width="16px" alt="" /> `
                }
            },
            {
                minWidth: 160, field: 'last_change_at', hide: false, headerName: 'Last Update', sortable: true, menuTabs: ['generalMenuTab', 'columnsMenuTab'], cellRenderer: (params) => {
                    if (params.value == null || params.value == undefined || params.value == '')
                        return 'NA'
                    var dateTime = toLocal(params.value, { inputFormat: 'YYYY-MM-DDTHH:mm:ss', outputFormat: OUTPUT_SHORTDATE_FORMAT })
                    return `${params.data.last_change_by}
                    <br />
                    <span class="small"> ${dateTime} </span>`
                }
            },
        ]
    },
    mounted() {
        this.getSitesMeta()
    },
    methods: {
        headerChangeCallback(checkedAll) {
            this.gridApi.forEachNode((node) => node.setSelected(checkedAll))
        },
        saveState(params) {
            this.table_Connection_Data.Provider_Login.LocationVerification_Headers = params.columnApi.getColumnState()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
        },
        applyState(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var columnState = tableState?.Provider_Login?.LocationVerification_Headers
            if (columnState) {
                params.columnApi.applyColumnState({ state: columnState, applyOrder: true })
            }
        },
        onFilterChanged(event) {
            this.table_Connection_Data.Provider_Login.LocationVerification_Filters = event.api.getFilterModel()
            localStorage.setItem(AG_GridCustomizedTableHeaders_Key, JSON.stringify(this.$store.state.CustomizedHeaders))
            this.fetchData(event)
        },
        setFilterModel(params) {
            var tableState = JSON.parse(localStorage.getItem(AG_GridCustomizedTableHeaders_Key))?.filter(s => s.userId == this.$store.state.user.id)[0]
            var filterModel = tableState?.Provider_Login?.LocationVerification_Filters
            if (filterModel) {
                params.api.setFilterModel(filterModel)
            }
        },
        onSortChanged(event) {
            this.saveState(event)
            this.fetchData(event)
        },
        onSelectionChanged(params) {
            if (params.api.getSelectedRows().length > 0 && !this.isEnable) {
                this.$refs.locationModal.$el.parentElement.children[0].children[0].children[0].children[1].children.disVal.click()
                this.isEnable = true
            }
            if (params.api.getSelectedRows().length == 0 && this.isEnable) {
                this.$refs.locationModal.$el.parentElement.children[0].children[0].children[0].children[1].children.disVal.click()
                this.isEnable = false
            }
        },
        DisableChange: function () {
            return !this.terms
        },
        ChangeSiteData(item, value) {
            if (!this.isEnable) {
                this.Listitems = []
                this.Listitems.push(item)
                this.verifyLocation = []
                this.Listitems.forEach(item => {
                    this.verifyLocation.push({
                        supplier_add1: item.address1,
                        supplier_add2: item.address2,
                        supplier_city: item.city,
                        supplier_state: item.state,
                        supplier_zip_code: item.zip_code,
                        supplier_org_id: item.supplier_org_id,
                        location_id: item.location_id,
                        site_id: value,
                        verify: item.verified,
                        invalid: item.invalid,
                        notcovered: item.notcovered,
                        emailed: item.emailed
                    })

                })
                this.updateVerifyLocation()
                this.Listitems = []
                this.verifyLocation = []
                this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
            }
        },

        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            this.fetchData({ api: this.gridApi, columnApi: this.columnApi})
        },
        getSitesMeta() {
            userServices.getSitesMetaforusers(getOrgId()).then(resp => {
                if (!resp.error) {
                    this.sitesMetas = resp.data.d.filter(s => s.status == 'ACTIVE')
                }
            })
        },

        checkboxChangeInvalid(item) {
            this.verifyLocation.push({ supplier_org_id: item.supplier_org_id, location_id: item.location_id, invalid: !item.invalid })
            this.updateVerifyLocation()
        },
        resetHeaderCheckbox(){
            document.getElementById('headerSelectAllcheckbox').checked = false
            this.gridApi.deselectAll()
            this.Listitems = []
            this.verifyLocation = []
            this.fetchData({ api: this.gridApi, columnApi: this.columnApi })
        },
        VerifyAllChange() {
            this.verifyLocation = []
            this.gridApi.getSelectedRows()?.forEach(item => {
                this.verifyLocation.push({ supplier_add1: item.address1, supplier_add2: item.address2, supplier_city: item.city, supplier_state: item.state, supplier_zip_code: item.zip_code, supplier_org_id: item.supplier_org_id, location_id: item.location_id, verify: true, invalid: false, site_id: item.site_id })
            })
            this.verifyLocation.forEach(item => {
                item.verify = true
                item.invalid = false
            })
            this.updateVerifyLocation()
            this.resetHeaderCheckbox()
        },
        EmailedAllChange() {
            this.verifyLocation = []
            this.gridApi.getSelectedRows()?.forEach(item => {
                this.verifyLocation.push({ supplier_add1: item.address1, supplier_add2: item.address2, supplier_city: item.city, supplier_state: item.state, supplier_zip_code: item.zip_code, supplier_org_id: item.supplier_org_id, location_id: item.location_id,emailed: true, verify: false,  invalid: false, site_id: item.site_id })
            })
            this.verifyLocation.forEach(item => {
                item.emailed = true
                item.verify = false
                item.notcovered = false
                item.invalid = false
            })
            this.updateVerifyLocation()
            this.resetHeaderCheckbox()
        },
        NotCoveredAllChange() {
            this.verifyLocation = []
            this.gridApi.getSelectedRows()?.forEach(item => {
                this.verifyLocation.push({ supplier_add1: item.address1, supplier_add2: item.address2, supplier_city: item.city, supplier_state: item.state, supplier_zip_code: item.zip_code, supplier_org_id: item.supplier_org_id, location_id: item.location_id, verify: false, invalid: false, notcovered: true, site_id: item.site_id })
            })
            this.verifyLocation.forEach(item => {
                item.verify = false
                item.invalid = false
                item.notcovered = true
            })
            this.updateVerifyLocation()
            this.resetHeaderCheckbox()

        },

        InvalidAllChange() {
            this.verifyLocation = []
            this.gridApi.getSelectedRows()?.forEach(item => {
                this.verifyLocation.push({ supplier_add1: item.address1, supplier_add2: item.address2, supplier_city: item.city, supplier_state: item.state, supplier_zip_code: item.zip_code, supplier_org_id: item.supplier_org_id, location_id: item.location_id, verify: true, invalid: false, site_id: item.site_id })
            })
            this.verifyLocation.forEach(item => {
                item.invalid = true
                item.verify = false
            })
            this.updateVerifyLocation()
            this.resetHeaderCheckbox()
        },
        getCentralizedSettingData(){
            locationService.getCentralizedIntakeData().then( resp =>{
                this.centralizedData = resp.data.data
                if(this.centralizedData == true){
                    this.showE = true
                    this.showV = false
                }
            })
        },
        fetchData(params) {
            document.getElementById('headerSelectAllcheckbox').checked = false
            params.api.deselectAll()
            this.applyState(params)
            this.setFilterModel(params)
            let that = this
            that.gridApi = params.api
            that.columnApi = params.columnApi
            const datasource = {
                getRows(params) {
                    that.searchQuery.colId = params.request?.sortModel[0]?.colId
                    that.searchQuery.sort = params.request?.sortModel[0]?.sort
                    that.search.supplieraddress1 = params.request?.filterModel?.address1?.filter
                    that.search.supplieraddress2 = params.request?.filterModel?.address2?.filter
                    that.search.suppliercity = params.request?.filterModel?.city?.filter
                    that.search.supplierstate = params.request?.filterModel?.state?.filter
                    that.search.supplierzipcode = params.request?.filterModel?.zip_code?.filter
                    that.search.provideraddress1 = params.request?.filterModel?.site_address1?.filter
                    that.search.provideraddress2 = params.request?.filterModel?.site_address2?.filter
                    that.search.providercity = params.request?.filterModel?.site_city?.filter
                    that.search.providerstate = params.request?.filterModel?.site_state?.filter
                    that.search.providerzipcode = params.request?.filterModel?.site_zip?.filter
                    that.search.status = params.request?.filterModel?.status?.filter
                    that.search.suporgname = params.request?.filterModel?.supplier_org_name?.filter
                    that.search.locationname = params.request?.filterModel?.location_name?.filter
                    that.search.filterModel = that.gridApi.getFilterModel()
                    locationService.findLocation({
                        ...that.searchQuery,
                        sortField: params.request?.sortModel[0]?.colId,
                        sortDirection: params.request?.sortModel[0]?.sort,
                        ...that.search
                    }).then(resp => {
                        if (!resp.error) {
                            const recallsarray = resp.data.d.c
                            that.total = resp.data.d.t
                            that.items = recallsarray
                            that.getCentralizedSettingData()
                            if(that.total == 0){
                                that.gridApi.showNoRowsOverlay()
                            }
                            else{
                                that.gridApi.hideOverlay()
                            }
                            return recallsarray
                        }
                    }).then(response => {
                        params.successCallback(response)
                    })
                        .catch(error => {
                            console.error(error)
                            params.failCallback()
                        })
                }
            }
            // register datasource with the grid
            params.api.setServerSideDatasource(datasource)

        },
        updateVerifyLocation() {
            locationService.updateVerifyLocation(this.verifyLocation).then(resp => {
                if (!resp.error) {
                    this.verifyLocation = []
                    this.onCompleted('UPDATE')
                }
                //after refresh :verify and invalid button disable
            })
        }
    }
}